import React from 'react';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';

const Footer5th: React.FC = () => {
  return (
    <footer css={footerStyle}>
      <div className="flex">
        <StaticImage
          src="../../images/5th/f_logo.png"
          height={50}
          quality={80}
          alt="Life Like a Live!5（えるすりー）"
          placeholder="none"
          loading="eager"
        />
        <p className="copyright">
          <small>&copy;Life Like a Live!制作委員会</small>
        </p>
      </div>
    </footer>
  );
};

const footerStyle = css`
  padding: 20px 25px;
  background-color: #fff;
  .flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;

    .copyright {
      small {
        font-size: 0.875em;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 18px 0 15px;
    .flex {
      text-align: center;
      display: block;
      .gatsby-image-wrapper-constrained {
        max-width: 67px;
      }
      .copyright {
        // margin: 10px 0 0;
        small {
          font-size: 10px;
        }
      }
    }
  }
`;

export default Footer5th;
