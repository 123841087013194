import React from 'react';

import { Global } from '@emotion/react';
import * as styles from './styles';
import Footer5th from '../Footer5th';
import 'modern-css-reset';

type Style = {
  style?: any;
};
const Layout5th: React.FC<Style> = ({ children, style = {} }) => {
  return (
    <>
      <Global styles={[styles.commonCss, styles.commonComp, styles.modalStyle, style]} />
      <div>
        <main>{children}</main>
        <Footer5th />
      </div>
    </>
  );
};

export default Layout5th;
